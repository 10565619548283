import React from 'react';

import { useRouter } from '../../../hooks/useRouter';
import { useGetPublicBoard } from '../../../graph/queries/getPublicBoard';

import { ViewWrapper } from './View.styles';
import { SkeletonBlock } from '../../../components/atoms/Skeleton/SkeletonBlock';
import { ErrorBoundary } from '../../../components/molecules/ErrorBoundary';
import { PublicBoardDescription } from '../../../components/organisms/BoardDescription/PublicBoardDescription';
import { InfoAlert } from '../../../components/atoms/InfoAlert';
import { Copy } from '../../../components/atoms/Typography';

export const BoardView: React.FC<{}> = () => {
  const { query: { userUuid = '', boardUuid = '' } = {}, navigate } = useRouter();
  const {
    error: boardError,
    data: { publicBoard } = {},
    loading: boardLoading,
  } = useGetPublicBoard({
    variables: { userUuid, boardUuid },
    fetchPolicy: 'network-only',
  });

  let boardComponent = null;
  if (boardError) {
    boardComponent = (
      <InfoAlert>
        <Copy>Sorry!</Copy>
        <Copy styleLevel={2}>
          The board you are looking for does not exist or you do not have permission to view it.
        </Copy>
      </InfoAlert>
    );
  } else if (boardLoading) {
    boardComponent = <SkeletonBlock style={{ height: '75px', marginBottom: '25px' }} />;
  } else if (publicBoard) {
    boardComponent = (
      <ErrorBoundary message="Route: \Board\View Element: Board Component">
        <PublicBoardDescription {...publicBoard} />
      </ErrorBoundary>
    );
  }

  return <ViewWrapper>{boardComponent}</ViewWrapper>;
};

import styled from 'styled-components';

import { media } from '../../../styles/media';

export const ViewWrapper = styled.div`
  /* border: 1px solid red; */
  padding-block-start: 16px;

  ${media.from_tablet`
    padding-block-start: 20px;
  `}
`;

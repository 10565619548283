import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { BoardDescriptionProps } from './BoardDescription.types';
import { ExternalLinkIcon, LawIcon, LocationIcon, SchoolIcon, StarIcon, SuitcaseIcon } from '../../atoms/Icons';
import { Pill } from '../../atoms/Pill';
import { Colors } from '../../../styles/colors';
import { Sections, Section } from '../../atoms/Section';
import { media } from '../../../styles/media';
import { formatDate } from '../../../utils/date';
import { convertEnumToString } from '../../../utils/enum';
import { File } from '../../atoms/File';
import { ErrorBoundary } from '../../molecules/ErrorBoundary';
import { PillsList } from '../../atoms/Pill/Pill';
import { Copy } from '../../atoms/Typography';

export const PublicBoardDescription: React.FC<any> = ({
  title,
  description,
  availableDate,
  linkedin,
  location,
  locationCity,
  locationCountry,
  locationLatitude,
  locationLongitude,
  locationPostCode,
  locationStreet,
  locationUrl,
  educationLevel,
  seniorityLevel,
  industry,
  interestLevel,
  coreSkills,
  workRightEU,
  workRightUK,
  isPublic,
  publicUrl,
  createdAt,
  updatedAt,
  files,
}: React.PropsWithChildren<BoardDescriptionProps>) => {
  return (
    <ErrorBoundary message="Organism\BoardDescription Root Component">
      <pre>
        {/* {JSON.stringify(
          {
            title,
            description,
            availableDate,
            linkedin,
            location,
            locationCity,
            locationCountry,
            locationLatitude,
            locationLongitude,
            locationPostCode,
            locationStreet,
            locationUrl,
            educationLevel,
            seniorityLevel,
            industry,
            interestLevel,
            coreSkills,
            workRightEU,
            workRightUK,
            createdAt,
            updatedAt,
            files,
            followers,
            isPublic,
          },
          null,
          2
        )} */}
      </pre>
      <Container>
        <HRSection />
        <Intro>
          <IntroMain>
            <JobTitleAndPills>
              <CompanyName>
                {title}
                {linkedin && (
                  <Link target="_blank" to={linkedin}>
                    <ExternalLinkIcon />
                  </Link>
                )}
              </CompanyName>
              {workRightEU && (
                <Pill icon={<LawIcon />} isSmall={true} color={Colors.Primary}>
                  Working Right EU
                </Pill>
              )}
              {workRightUK && (
                <Pill icon={<LawIcon />} isSmall={true} color={Colors.PrimaryDarkest}>
                  Working Right UK
                </Pill>
              )}
            </JobTitleAndPills>
            <Employment>
              {interestLevel && (
                <EmploymentContent>
                  <SuitcaseIcon size={1.5} />
                  {convertEnumToString('InterestLevel', interestLevel)}
                </EmploymentContent>
              )}
              {seniorityLevel && (
                <EmploymentContent>
                  <StarIcon size={1.5} />
                  {convertEnumToString('SeniorityLevel', seniorityLevel)}
                </EmploymentContent>
              )}
              {educationLevel && (
                <EmploymentContent>
                  <SchoolIcon size={1.5} />
                  {convertEnumToString('EducationLevel', educationLevel)}
                </EmploymentContent>
              )}
            </Employment>
            <Location>
              {location && (
                <>
                  <LocationIcon size={1.5} color={Colors.Contrast} />
                  {location}
                  {locationUrl && (
                    <LocationUrl target="_blank" to={locationUrl}>
                      <ExternalLinkIcon size={1} />
                    </LocationUrl>
                  )}
                </>
              )}
            </Location>
          </IntroMain>
          <IntroRight>
            {files?.length > 0 && (
              <Section>
                <SectionTitle>Attached Files</SectionTitle>
                {files.map((file) => {
                  return (
                    <SectionValue key={file.uuid}>
                      <File
                        onDownloadClick={() => {
                          if (!file.temporaryUrl) return;
                          window.open(file.temporaryUrl, '_blank');
                        }}
                        uuid={file.uuid}
                        filename={file.filename}
                        mimeType={file.mimetype}
                        formattedMimetype={file.formattedMimetype}
                      />
                    </SectionValue>
                  );
                })}
              </Section>
            )}
          </IntroRight>
        </Intro>
        <HRSection />
        <Sections>
          <Section>
            <SectionTitle>Available</SectionTitle>
            {availableDate ? (
              <SectionValue>
                {formatDate({
                  date: availableDate,
                  format: 'long',
                })}
              </SectionValue>
            ) : (
              <SectionValue>Unknown</SectionValue>
            )}
          </Section>
          <Section>
            <SectionTitle>Skills</SectionTitle>
            {coreSkills?.length > 0 ? (
              <PillsList>
                {coreSkills.map((coreSkill, i) => {
                  return (
                    <Pill key={i} isSmall={true} color={Colors.Primary}>
                      {coreSkill}
                    </Pill>
                  );
                })}
              </PillsList>
            ) : (
              <SectionValue>No yet defined</SectionValue>
            )}
          </Section>
        </Sections>
        <Sections>
          <Section>
            <SectionTitle>Industry</SectionTitle>
            <SectionValue>{convertEnumToString('Industry', industry)}</SectionValue>
          </Section>
          <Section>
            <SectionTitle>History</SectionTitle>
            <SectionValue>
              <Copy styleLevel={3}>
                Created on{' '}
                {formatDate({
                  date: createdAt,
                  format: 'short',
                })}
              </Copy>
            </SectionValue>
            <SectionValue>
              <Copy styleLevel={3}>
                Updated on{' '}
                {formatDate({
                  date: updatedAt,
                  format: 'short',
                })}
              </Copy>
            </SectionValue>
          </Section>
        </Sections>
        <HRSection />
        {description && (
          <Sections>
            <Section>
              <SectionTitle>Description</SectionTitle>
              <SectionDescription>{description}</SectionDescription>
            </Section>
          </Sections>
        )}
      </Container>
    </ErrorBoundary>
  );
};

const Container = styled.div``;

const Intro = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const IntroMain = styled.div`
  flex: 4;
`;

const IntroRight = styled.div`
  flex: 1.5;
`;

const CompanyName = styled.div`
  display: flex;
  font-size: 2rem;
`;

const Location = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
  font-size: 1.1rem;
`;

const Employment = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  margin-bottom: 8px;

  ${media.from_laptop`
    justify-content: flex-start;
    margin-bottom: 16px;
  `}
`;

const EmploymentContent = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const JobTitleAndPills = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 8px;

  ${media.from_laptop`
    justify-content: flex-start;
    gap: 16px;
  `}
`;

const SectionTitle = styled.div`
  color: ${Colors.GreyLightest};
  font-size: 1.2rem;
  line-height: 2rem;
  display: flex;
`;

const SectionValue = styled.div`
  display: flex;
  gap: 4px;
  color: ${Colors.GreyDarkest};
  font-size: 1.2rem;
  line-height: 2rem;
  margin-bottom: 8px;
`;

const SectionDescription = styled.div`
  color: ${Colors.Black};
  font-size: 1rem;
  line-height: 2rem;
`;

const HRSection = styled.hr`
  border: 0;
  height: 1px;
  background-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0),
    hsl(300, 15%, 70%, 1),
    hsl(300, 15%, 70%, 1),
    hsl(300, 15%, 70%, 1),
    hsl(300, 15%, 70%, 1),
    rgba(0, 0, 0, 0)
  );

  margin: 8px 0 16px 0;

  ${media.from_tablet`
    margin: 8px 0 32px 0;
  `}
`;

const LocationUrl = styled(Link)`
  transform: translateY(-8px);
`;

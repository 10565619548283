import { gql, useQuery, useLazyQuery, QueryHookOptions } from '@apollo/client';

import { Query, QueryPublicBoardArgs } from '../../generated/types';

export const GET_PUBLIC_BOARD = gql`
  query publicBoard($userUuid: String!, $boardUuid: String!) {
    publicBoard(userUuid: $userUuid, boardUuid: $boardUuid) {
      uuid

      title
      description
      linkedin
      availableDate
      location
      locationStreet
      locationPostCode
      locationCity
      locationCountry
      locationUrl
      locationLatitude
      locationLongitude
      educationLevel
      interestLevel
      seniorityLevel
      workRightEU
      workRightUK
      industry
      coreSkills

      files {
        uuid
        filename
        size
        mimetype
        formattedMimetype
        resource
        createdAt
        updatedAt
        isDeleted
        temporaryUrl
        aiCoreSkills
        aiSoftSkills
        aiJobTitle
        aiIsValidResume
      }

      createdAt
      createdBy {
        uuid
        email
        nickname
        name
      }
      updatedAt
      updatedBy {
        uuid
        email
        nickname
        name
      }

      isPublic
      publicUrl

      isNew
      isDeleted
    }
  }
`;

export const useGetPublicBoard = (options?: QueryHookOptions<Pick<Query, 'publicBoard'>, QueryPublicBoardArgs>) =>
  useQuery<Pick<Query, 'publicBoard'>, QueryPublicBoardArgs>(GET_PUBLIC_BOARD, options);

export const useLazyGetPublicBoard = (options?: QueryHookOptions<Pick<Query, 'publicBoard'>, QueryPublicBoardArgs>) =>
  useLazyQuery<Pick<Query, 'publicBoard'>, QueryPublicBoardArgs>(GET_PUBLIC_BOARD, options);
